body.post-template-default {
    article {
        padding-left: 2rem;
        border-left: 1px solid #303030;
        
    
        .entry-header {
            
            h1.entry-title {
                position: relative;
        
                &::before {
                    height: 100%;
                    width: 1px;
                    background-color: #606060;
                    display: block;
                    content: "";
                    left: -2rem;
                    top: 0;
                    position: absolute;
                }
            }
        }

    }
}

body:not(.home) {
    article {
        h1.entry-title {
            font-family: 'Lato', sans-serif;
            font-size: 1.8125rem;
            font-weight: bold;
            padding: .5rem 0;
        }
    }
}

body.page {
    article {
        h1.entry-title {
            text-transform: uppercase;
        }
    }
}

article {
    font-family: 'Lato', sans-serif;
    font-size: 1.0625rem;
    p {
        margin-bottom: 1rem;
    }
}