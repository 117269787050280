
.vid-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.5);
    position: relative;
    max-height: calc(100vh - 242px);
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media screen and (min-width: 768px) {
            width: 87%;
        }
    }

    @media screen and (min-width: 768px) {
        position: absolute;
        clip-path: polygon(0 0, 85.3% 0, 32.8% 100%, 0% 100%);
        max-height: 100%;
    }
}

div.glowna {
    // display: flex;
    // flex-direction: column;
    div.text {
        display: flex;
        flex-direction: row;
        font-family: Lato;
        font-size: 0,8125rem;
        font-weight: 400;
        color: #d7d7d7;
        margin-bottom: 10px;
    }

    h2.title {
        font-family: Raleway;
        font-size: 2.5625rem;
        font-weight: 600;
        line-height: 2.6875rem;
        color: white;
        text-transform: uppercase;
    }

    article {
        display: flex;
        h2 {
            font-family: Lato;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 23px;
            max-width: 250px;
        }
        p {
            font-family: Lato;
            font-weight: 400;
            font-size: 1.0625rem;
            text-align: start;
        }
        li {
            font-family: Lato;
            font-weight: 400;
            font-size: 1.0625rem;
            list-style: none;
            position: relative;
            padding-left: 20px;
            &::before {
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #616161;
                transform: translate(-20px,12px);
            }
        }
    }
    div.articles {
        display: flex;
        flex-wrap: wrap;
        padding: 80px 0 80px 0;

        
        article {
            display: flex;
            margin: 0 0px 80px 0;
            cursor: pointer;
            color: white;
            width: 100%;

            @media screen and (min-width: 768px) {
                width: 50%;
            }

            @media screen and (min-width: 1200px) {    
                width: 33%;
            }

            @include containerBigRules;
            
            div.lineBox {
                min-width: 1.875rem;

                @media screen and (min-width: 992px) {
                    min-width: 80px;
                }
                @media screen and (min-width: 1200px) {
                    min-width: 2rem;
                }
                @media screen and (min-width: 1440px) {
                    min-width: 80px;
                }
            }
            
            .content {
                display: flex;
                flex-direction: column;
                h2 {
                    font-family: Lato;
                    font-size: 1.0625rem;
                    font-weight: 700;
                    margin-bottom: 30px;
                    width: 100%;
                }
                div.text {
                    @media screen and (min-width: 992px) {
                        margin-right: 80px;
                    }
                }
            }
        }
    }
    div.containerBig {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include containerBigRules;
        
        @media screen and (min-width: 1200px) {
            width: 50%;
            padding: 0 100px;
        }

        h2.title {
            margin-bottom: 80px;
        }
        article {
            margin-bottom: 80px;
            .content {
                display: flex;
                flex-direction: column;
                p {
                    margin-bottom: 30px;
                }
                p, li {
                    color: white;
                }
            }
        }
    }
    div.more {
        font-family: Lato;
        font-size: 1.0625rem;
        line-height: 19px;
        font-weight: 700;
        color: white;
        display: flex;
        align-items: center;
        margin-top: auto;
        span {
            margin-left: 10px;
            display: inline-block;
        }
    }
    div.thumbnailBig {
        width: 50%;
        img {
            border-bottom: 20px solid #636363;
        }
    }
    div._90deg {
        background-color: #161716;
        align-items: center;
        text-align: left;
        padding: 1.5rem;
        
        @media screen and (min-width: 1200px) {
            min-width: 180px;
            max-width: 180px;
            text-align: initial;
            padding: 0;
        }

        p {
            font-family: Raleway;
            font-size: 2.5625rem;
            line-height: 43px;
            font-weight: 600;
            color: white;
            text-transform: uppercase;
            margin: 0;
            
            @media screen and (min-width: 1200px) {
                transform: rotate(270deg) translateX(-140px);
                direction: rtl;
            }
        }
    }

    section.zakladowa {
        margin-bottom: 12rem;
        background: url(../img/bet.jpg) no-repeat top left;

        .content {
            display: flex;
            flex-direction: column;
        }
        
        div.whiteGrey {
            display: flex;
            flex-direction: column;
            min-width: 215px;
            div.white {
                height: 500px;
            }
            div.grey {
                background-color: #646464;
                height: 200px;
            }
        }
        div.block {
            display: flex;
            background-color: #1e1e1e;
            flex-direction: column;
            max-width: 100vw;
            
            @media screen and (min-width: 1200px) {
                flex-direction: row;
                padding-right: 215px;
            }
        }

        
    }
    
    section.z-2 {
        background: #313131;
        margin-bottom: 0;
        padding-bottom: 50px;
    }

    section.projektowanie {
        padding-bottom: 150px;
        background: linear-gradient(to bottom, #313131, 60%, #313131, 60%, #646464);
        padding-top: 10rem;
        background-color: #646464;
        overflow-x: hidden;

        div.whiteGreyLeft, div.whiteGreyRight {
            display: flex;
            flex-direction: column;
            min-width: 215px;
            div.grey {
                background-color: #313131;
                height: 650px;
            }
        }
         
        div.box {
            display: flex;
            flex-grow: 1;
            // background-color: #1e1e1e;

            div.img {
                width: 340px;
                overflow: hidden;
                height: 284px;
                img.down {
                    width: 100%;
                    height: auto;
                    transform: translate(1);
                }
            }

            div.left {
                width: 100%;
                
                @media screen and (min-width: 1200px) {
                    width: 55%;
                }
                // width: 615px;
                div.containerBig {
                    width: 100%;
                    border-bottom: 15px solid #161716;
                    background: #1e1e1e;
                    
                    @include containerBigRules;

                    @media screen and (min-width: 1200px) {
                        
                        padding: 0 70px 0 150px;
                    }

                    h2 {
                        margin-top: 70px;
                    }
                    article {
                        margin: 0;
                    }
                    div.more {
                        margin: 60px 0;
                    }
                }
                
                div.down {
                    display: none;
                    background-color: #646464;  
                    justify-content: flex-end;
                    width: 100%;
                    
                    @media screen and (min-width: 1200px) {
                        display: flex;
                    }
                }
            }
            div.right {
                background: #1e1e1e;
                min-height: 570px;
                display: none;
                flex-direction: column;
                justify-content: flex-end;

                
                @media screen and (min-width: 1200px) {
                    display: flex;
                }
                
                div.block {
                    display: flex;
                    
                }
                div.null {
                    flex-grow: 1;
                    border-right: 30px solid transparent;
                }
                div.one {
                    display: flex;
                    border-right: 30px solid transparent;
                    div.img {
                        background-color: #646464;
                    }
                }
                div.w {
                    background-color: white;
                }
                div.g {
                    background-color: #f1f2f7;
                }

                
            }
        }
    }

    section.nadzor {
        div.whiteGrey {
            display: flex;
            flex-direction: column;
            min-width: 215px;
            div.grey {
                background-color: #646464;
                height: 200px;
            }
        }
        div.block {
            max-width: 100vw;

            div.up {
                display: flex;
                background-color: #1e1e1e;
                flex-direction: column;
                
                @media screen and (min-width: 1200px) {
                    flex-direction: row;
                    padding-right: 215px;
                }
                
            }
            div.down {
                display: flex;
                height: 320px;
                background-color: #313131;
                padding-right: 215px;
                div.sliders {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: flex-end;
                    background-color: #bcbcbf;
                    min-width: 180px;
                    max-width: 180px;
                    div.container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        width: 70px;
                        height: 45%;
                        background-color: #1e1e1e;
                        div.right, div.left {
                            display: flex;
                            height: 49px;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                        div.line {
                            height: 1px;
                            background-color: white;
                            width: 50%;
                            margin: 0 auto;
                        }
                    }
                }
                div {
                    width: 100%;
                    height: 100%;
                    div.img {
                        width: 66%;
                        background-image: url("../img/nadzór.webp");
                        background-position: center;
                        background-size: cover;
                    }
                }

            }
        }
    }
    section.diagnostyka {
        min-height: 50vh;
        background-color: #1f1f1f;
        margin: 50px 0;
        padding: 70px 0 70px 0;

        div.containerBig {
            @include containerBigRules;

            @media screen and (min-width: 1200px) {
                padding-left: 150px;
            }
        }
    }
    section.geologia {
        min-height: 50vh;
        background-color: #e2e2e2;
        // width: calc(100vw - 215px);
        background-color: #1f1f1f;
        padding: 70px 0;
    }

    section.aktualnosci {
        background-color: white;

        > .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 10%;

        }

        h2.title {
            margin: 100px 0 60px 0;
            width: 100%;
            color: #313131;
        }
        div.artykuly {
            display: flex;
            margin-bottom: 120px;
            flex-direction: column;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }

            article {
                margin-right: 50px;
                color: #1e1e1e;
                margin-bottom: 1.5rem;
                
                @media screen and (min-width: 768px) {
                    margin-bottom: 0;
                }
                
                .content {
                    p.date {
                        font-size: 0.875rem;
                        line-height: 19px;
                        color: #505050;
                        margin: 10px 0 10px 70px;
                        &::before {
                            display: block;
                            content: '';
                            width:  60px;
                            height: 1px;
                            background-color: #505050;
                            transform: translate(-70px, 10px);
                        }
                    }
                    img.more {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    section.fs {
        .content {
            display: flex;
            flex-direction: column;
            
            @media screen and (min-width: 1200px) {
                flex-direction: row;
            }

            &.rm {
                flex-direction: column-reverse;
            }
        }

    }
    .thumbnailBig {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 2rem auto 0 auto;
        
        @media screen and (min-width: 1200px) {
            text-align: unset;
            margin: 0;
        }
    }
}




.white-hr {
    background: #afafaf;
    width: 70px;
    height: 1px;
    margin-bottom: 1rem;
    padding-top: 1px;
}

a, a:hover, a:visited {
    color: inherit;
    text-decoration: inherit;
}

section.hero-section {
    .content {
        display: flex;
        height: 100%;
        flex-grow: 1;
        background-color: #2b2b2b;
        max-width: 100vw;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        
        @media screen and (min-width: 768px) {
            flex-direction: row;
            min-height: 100vh;
        }

        p {
            font-family: Raleway;
            font-weight: 700;
            color: white;
            font-size: 3rem;
            margin-left: 30px;
            margin-right: 30px;
            
            @media screen and (min-width: 768px) {
                font-size: 5.75rem;
                margin-left: 130px;
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        .bottom-block .text-wrap {
            width: 100%;
            text-align: start;
            background: #000000;
            color: white;
            padding: .35rem .65rem;

            p {
                font-size: 1rem;
                margin-left: 0;
            }
        }
        background-image: url("../img/main.webp");
        background-repeat: no-repeat;
        // background-position: right;    
        background-size: auto 100%;
        background-position: bottom right;
    }

    .top-block {
        position: absolute;
        top: 52px;
        left: 2rem;
        z-index: 2;
        max-width: calc(100vw - 4rem);
        display: none; 

        @media screen and (min-width: 1200px) {
            display: block;
        }
    }

    .right-block {
        position: relative;
        top: 0;
        right: 0;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        
        @media screen and (min-width: 768px) {
            position: absolute;
            height: calc(100vh - 130px - 1.7rem);
            max-width: 17rem;
        }

        @media screen and (min-width: 1200px) {
            height: calc(100vh - 75px - 1.7rem);
        }

        @media screen and (min-width: 1600px) {
            height: calc(100vh - 130px - 1.7rem);
        }

        .see-more {
            padding: 1.5rem;
            font-size: 1rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &.open {
                img {
                    transform: scaleY(-1);
                }
            }
            
            img {
                margin-left: 1rem;
                transition: transform .3s;
            }
        }

        .actual-text {
            display: none;

            &.open {
                padding: 0 1.5rem 0rem 1.5rem;
                display: block;
            }

            @media screen and (min-width: 768px) {
                display: block;

                &.open {
                    padding: 0;
                }
            }
        }

        .logos-wrap {
            display: grid;
            align-self: flex-end;
            align-content: center;
            flex-direction: column;
            grid-template-columns: repeat(3, 1fr);
            background: white;
            // height: 100%;

            .logo {
                &:first-child {
                    background: white;
                }

                &:nth-child(2) {
                    margin-top: auto;
                }

                padding: .75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                img {
                    max-height: 75px;
                    height: 100%;
                    width: auto;
                    object-fit: contain;

                }
            }
            
            @media screen and (min-width: 768px) {
                display: flex;
                flex-direction: column;
                background: transparent;
                align-items: center;
                width: 100%;
                justify-content: center;

                .logo {
                    padding: 1.5rem 1.5rem;

                    img {
                        max-height: 123px;
                    }
                }
            }

            @media screen and (min-width: 1200px) {
                .logo {
                    padding: .9rem;

                    img {
                        max-height: 70px;
                    }
                }

            }
            @media screen and (min-width: 1600px) {
                .logo {
                    padding: 1.5rem 1.5rem;

                    img {
                        max-height: 123px;
                    }
                }

            }
            
        }

        .text-wrap {
            // padding: 1.5rem 1.5rem 0 1.5rem;
            color: black;
            background: white;
            font-size: .9rem;
            line-height: 1.1;
            width: 100%;
            
            @media screen and (min-width: 768px) {
                padding: 0 1.5rem 1.5rem 1.5rem;
            }
            @media screen and (min-width: 1200px) {
                padding: 0 .75rem .75rem .75rem;
            }
            @media screen and (min-width: 1600px) {
                padding: 0 1.5rem 1.5rem 1.5rem;
            }

            p {
                margin-left: auto;
                font-size: inherit;
                color: inherit;
            }

            ul {
                margin-top: .75rem;
            }
        }

        .bottom-logos-wrap {
            margin-top: auto;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: none;
            @media screen and (min-width: 768px) {
                display: block;
            }

            img {
                max-height: 7.5rem;
                width: auto;
                margin-bottom: 1rem;
                
                @media screen and (min-width: 1200px) {
                    max-height: 4rem;
                }

                @media screen and (min-width: 1600px) {
                    max-height: 7.5rem;
                }
            }
        }
    }


    .bottom-block {
        position: relative;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        max-width: 100vw;
        flex-direction: column;


        @media screen and (min-width: 768px) {
            position: absolute;
        }

        .top-bar {
            display: grid;
            grid-template-columns: auto 50px;
            justify-content: stretch;
    
            @media screen and (min-width: 768px) {
                justify-content: flex-end;
            }
            
            &-content {
                display: grid;
                color: white;
                flex-direction: column;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
                
                @media screen and (min-width: 768px) {
                    display: flex;
                    flex-direction: row;
                    width: auto;
                    max-width: 100%;
                    overflow-x: hidden;
                    justify-content: flex-end;
                }
                
                .item {
                    padding: 2.5rem 3rem;
                    justify-content: flex-start;
                    
                    @media screen and (min-width: 768px) {
                        padding: 2rem 1.7rem;
                        max-width: 25%;
                        justify-content: center;
                    }
                    @media screen and (min-width: 1200px) {
                        padding: 1rem 1.75rem;
                    }
                    @media screen and (min-width: 1600px) {
                        padding: 2.5rem 4rem;
                    }
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 1.1rem;
                    font-family: 'Raleway';
                    height: 100%;
                }
                > .item.s-0 {
                    background: #000000;
                }
                > .item.s-1 {
                    background: #090909;
                }
                > .item.s-2 {
                    background: #111111;
                }
                > .item.s-3 {
                    background: #1A1A1A;
                }
                > .item.s-4 {
                    background: #222222;
                }
                > .item.s-5 {
                    background: #2b2b2b;
                }
            }
            &-arrows {
                background: #1d1d1d;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                
                .separator {
                    padding-top: 1px;
                    background: white;
                    width: 100%;
                }
            }
        }
    }
}