
































































.top-bar-arrows {
    .next, .prev {
        cursor: pointer;
        text-align: center;
    }
}
