@import "variables.scss";
@import "parts/contact.scss";
@import "parts/firm.scss";
@import "parts/index.scss";
@import "parts/menu.scss";
@import "parts/partners.scss";
@import "parts/realization.scss";

@import "utilities/reset.scss";

:root {
    --sideheader-width: 215px;

    font-size: 12px;
    
    @media screen and (min-width: 1200px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1440px) {
        font-size: 16px;
    }
}

body { 
    overflow-x: hidden;
}

body:not(.home) {
    section.grid-section {
        .content {
            @media screen and (min-width: 1200px) {
                max-width: calc(100vw - var(--sideheader-width));
            }
        }
    
        &:not(.footer) {
                .content {
                    max-width: calc(100vw - (2 * 18px));
        
                    @media screen and (min-width: 768px) {
                        max-width: calc(100vw - (2 * 48px));
                    }
                    @media screen and (min-width: 1200px) {
                        max-width: min(calc(100vw - var(--sideheader-width)), 900px);
                    }
                    @media screen and (min-width: 1440px) {
                        max-width: min(100vw, 1275px);
                    }
                }
            }
    }
}

section.grid-section {
    display: grid;
    grid-template-columns: auto;
    
    @media screen and (min-width: 1200px) {
        grid-template-columns: var(--sideheader-width) auto;
    }

    &.fill {
        flex-grow: 1;
    } 

    .empty {
        width: var(--sideheader-width);
        height: 100%;
    }

    .content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

div.wrapper {
    display: flex;
    flex-direction: row;
}

div.lineBox {
    display: flex;
    flex-direction: column;
    min-width: 1.875rem;
    
    @media screen and (min-width: 992px) {
        min-width: 2.5rem;
    }
    @media screen and (min-width: 1200px) {
        min-width: 2;
    }
    @media screen and (min-width: 1440px) {
        min-width: 2.5rem;
    }

    div.bold {
        height: 40px;
        border-left: 1px solid #606060;
    }
    div.thin {
        flex-grow: 1;
        border-left: 1px solid #303030;
    }
}

div.lineBoxWhite {
    display: flex;
    flex-direction: column;
    min-width: 40px;
    div.bold {
        height: 40px;
        border-left: 1px solid #606060;
    }
    div.thin {
        flex-grow: 1;
        border-left: 1px solid #d3d3d3;
    }
}

img.more {
    // transform: scale(.9);
    cursor: pointer;
}

.splide__slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    min-height: 100vh;
}

.content-container {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 1200px) {
        max-width: min(100vw, 1275px);
    }
}

main#primary {
    max-width: 100%;
}

.container-partners {
    background: #101010;
}

.grid-section.main-section {
    background: #1e1e1e;
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    @media screen and (min-width: 1200px) {
        min-height: calc(100vh - 258px);
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    @media screen and (min-width: 1440px) {
        min-height: calc(100vh - 263px);
    }
}

.swiper-wrapper {
    align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
    display: none !important;
}

.grid-2x2 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 4rem;
    column-gap: 4rem;
    
    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

article.template {
    display: flex;
    height: 100%;

    h2 {
        max-width: 400px;
    }
    .lineBoxWhite {
        .bold {
            border-left: 1px solid #606060;
        }
        .thin {
            border-left: 1px solid #303030;
        }
    }
    > .content {
        display: flex;
        flex-direction: column;
        
        img.more {
            margin-top: auto;
        }
    }
}


h1.entry-title {
    font-family: "Raleway";
    font-weight: 600;
    font-size: 2.5625rem;
    text-transform: uppercase;
}

.entry-meta {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    
    .grey-horizontal-line {
        background: #afafaf;
        padding-top: 1px;
        width: 70px;
        margin-right: 1rem;
    }
}

body.page-template-default.page {
        article {
            .entry-header {
                margin-bottom: 2rem;
            }
        }
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 0 !important;
}

body.post, body.page {
    margin-bottom: 0; 
}

h1.cat-title {
    text-transform: uppercase;

}

.go-back {
    display: none;
    align-self: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        display: flex;
    }   

    img {
        margin-right: 1rem;
    } 
}


body > .side-header + .grid-section > .content {
    min-height: calc(100vh - 263px);
    display: flex;
    // flex-direction: column
}

// .grid-3x1 {
//     display: grid;
//     row-gap: 2rem;
//     column-gap: 3rem;
//     grid-template-columns: 1fr;

//     @media screen and (min-width: 768px) {
//         grid-template-columns: repeat(2, 1fr);
//     }

//     @media screen and (min-width: 1200px) {
//         grid-template-columns: repeat(3, 1fr);
//     }

// }

.swiper-container {
    overflow-x: hidden;
}

footer {
    .content-container {
        max-width: calc(100vw);
        
        @media screen and (min-width: 1200px) {
            max-width: calc(100vw - var(--sideheader-width));

        }
        @media screen and (min-width: 1440px) {
            max-width: calc(100vw - var(--sideheader-width) - 200px);

        }
    }
}

@import "parts/article.scss";
@import "pages/contact.scss";
@import "pages/certificates.scss";
@import "pages/archive.scss";

@import "bootstrap-grid.min.css";