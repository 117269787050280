
.page-template-certificates {
    .certs-wrap {
        color: #d7d7d7;
        column-gap: 6rem;
        row-gap: 4rem;
        margin-top: 4rem;

        display: grid;
        grid-template-columns: 1fr;

        
        @media screen and (min-width: 576px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .cert {
            display: flex;
            flex-direction: column;
        
            img {
                width: 100%;
                max-height: 500px;
                height: 100%;
                object-fit: cover;
                margin-bottom: 2rem;
            }
            .label {
                // margin-top: 2rem;
            }
        }
    }
}