header.side-header {
    width: var(--sideheader-width);
    position: fixed;
    top: 0;
    left: -200vw;
    z-index: 200;
    transition: .5s left ease-in-out;

    &.open {
        left: 0;
    }

    @media screen and (min-width: 1200px) {
        left: 0;

        .positions {
            overflow-y: auto;
        }
    }
}

.mobile-top-header {
    background: black;
    position: sticky;
    top: 0;
    padding: 1rem;
    z-index: 5;
    display: flex;

    @media screen and (min-width: 1200px) {
        display: none;
    }

    .logo {
        max-height: 64px;
        img {
            width: auto;
            height: 100%;
        }

        a {
            display: flex;
            height: 100%;
            align-items: flex-start;
        }
    }

    .lab-title {
        max-width: 40vw;
        margin-top: 0.8rem;
        margin-left: 1rem;
    }

    .search-wrap {
        padding-left: 1rem;
        padding-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .overlay-form {
            display: none !important;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #0000008a;
            z-index: 500;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 2rem;


            form#searchform {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                transform: none;
                margin-top: 2rem;
            }
        }

        &.search-open {
            .overlay-form {
                display: flex !important;
                
                form {
                    display: block !important;
                }
            }

            img {
                display: none;
            }
        }
    }

    #mobile-menu-toggle {
        display: flex;
        align-items: center;
        color: white;
        font-size: 2.5rem;
    }

}

section.menu {
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: fit-content;
    background-color: #161616;
    color: white;
    img.line {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    div.logo {
        margin: 30px 30px 65px 30px;
        img {
            width: 105px;
            height: auto;
            
            @media screen and (min-width: 992px) {
                width: 155px;
            }
        }
    }
    div.positions {
        &::-webkit-scrollbar {
            width: 4px;
            
            &-track {
                background: #ffffff0a;
            }

            &-thumb {
                background: #ffffff15;
            }
        }
        scrollbar-width: 4px;  /* Firefox */
        scrollbar-color: #ffffff0a;  /* Firefox */

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        margin: 0 30px 50px 30px;
        padding: 0;

        ul {
            list-style: none;   
            font-family: 'Raleway';
            font-size: 1.25rem;
            text-transform: capitalize;
            position: relative;
            margin-left: 0;
            margin-bottom: 0;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 1px;
                // height: 90%;
                top: 15px;
                background-color: #494949;
                transform: translateX(2px);
                // margin: 10px 0;
            }
            li {
                &::after {
                    content: '';
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: white;
                }
                position: relative;
                width: 100%;
                text-align: left;
                img {
                    &#lupa {
                        position: absolute;
                    }
                    display: block;
                    margin: 0 0 0 20px;
                    transform: scale(.8);
                }
            }
            li.strictMenu {
                padding-left: 20px;
                line-height: 2.5rem;
                &::after {
                    transform: translate(-20px,-22px);
                }
                a {
                    text-decoration: none;
                    color: white;
                    &:visited {
                        color: white;
                    }
                    &[aria-current] {
                        font-weight: 600;
                    }
                }
            }    
            li.search::after, li.socials::after {
                transform: translateY(-18px);
            }
            li.search {
                margin: 1rem 0;
                visibility: hidden;
                
                @media screen and (min-width: 1200px) {
                    visibility: visible;
                    margin: 3.5rem 0;
                }

                form {
                    display: none;
                }

                &.search-open {
                    form {
                        display: block !important;
                    }

                    img {
                        display: none;
                    }
                }
            }
        }
    }
    div.concrete {
        background-image: url("../img/tło_beton.webp");
        background-position: center;
        background-size: cover;
        height: 100%;
    }
}

section.alt-menu {
    position: absolute;
    left: -200vw;
    top: 0;
    z-index: 5;
    background: black;
    transition: .5s left ease-in-out;
    padding: 2rem;

    &:not(.aboutUsMenu) {
        @media screen and (min-width: 1200px) {
            padding: 10rem;
        }
    }

    &.open {
        left: 0;
        
        @media screen and (min-width: 1200px) {
            left: var(--sideheader-width);
        }
    }

    &.offerMenu {
        width: 100vw;
        overflow-y: scroll;
        
        @media screen and (min-width: 1200px) {
            overflow-y: auto;
            width: calc(100vw - var(--sideheader-width));
        }

        .title {
            max-width: 190px;
            font-family: 'Lato';
            font-size: 1.25rem;
            font-weight: bold;
        }

        hr {
            height: 1px;
            background: #383838;
            width: 100%;
            border: 0;
            margin-top: 20px;
        }

        .positions {
            margin: 0;
            padding: 0;

            li.strictMenu {
                line-height: 1.8rem;
                position: relative;

                &::after {
                    transform: translate(0, 0);
                    left: 0;
                    top: 0.7rem;
                    position: absolute;
                }
            }

            a {
                font-size: 1.0625rem;
                font-family: 'Lato';
            }
        }
    }

    .grid-4x1 {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2rem;

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
        }

        
    }

    .grid-3x1 {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 4rem;
        
        @media screen and (min-width: 1200px) {
            grid-template-columns: 2fr 3fr 2fr 1fr;
        
        }
    }

    .back-button {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        @media screen and (min-width: 1200px) {
            display: none;
        }

        img {
            margin-right: 1rem;
        }

    }
}

form[role="search"] {
    border-radius: 8px;
    max-width: 300px;
    font-family: Lato;
    font-size: 17px;
    line-height: 54px;
    margin-left: 15px;
    position: absolute;
    box-shadow: 0 0 3px 2px #ffffff;
    transform: translateY(-43px);
    z-index: 20;
    padding: .5rem;
    background-color: #1e1e1e;
    position: absolute;
    right: 50px;
    top: 60px;
    
    @media screen and (min-width: 1200px) {
        box-shadow: 0 0 2px #ffffff;
        position: fixed;
        right: initial;
        top: initial;
        margin-top: 0.8rem;
    }
    
    > div {
        display: flex;
    }
    
    input {
        color: white !important;
        background-color: #1e1e1e;
        outline: none;
        border-width: 0px;
        padding-left: .5rem;
        padding-right: .5rem;
    }
    
    #searchsubmit {
        background-image: url("../img/search.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0.6em 1.1em 0.4em;
    }
}

.menu-grid {
    > div {
        padding: 1.5rem 1rem;
        transition: .3s background;

        a {
            &:hover, &:focus {
                text-decoration: underline !important;
            }   
        }

        @media screen and (min-width: 1200px) {
            &:hover {
                background: #161616;
            }
        }
    }
}

.search-image {
    cursor: pointer;
}