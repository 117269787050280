body.page-id-8 .grid-section .content.content {
    max-width: 100vw !important;
}

section.contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #1e1e1e;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }

    .content {
        max-width: 100vw;
    }

    p {
        margin-bottom: 0;
    }
    // height: calc((100vw-215px)/2);
    div.contact, div.map {
        width: 100%;
        min-height: 65vh;
        display: grid;
        
        @media screen and (min-width: 768px) {
            width: 50%;
        }

        @media screen and (min-width: 1200px) {
            min-height: unset;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
    .map {
        > iframe {
            width: 100%;
            height: 100%;
        }
    }
    div.contact {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: white;
        padding: 50px 0;

        @include containerBigRules;

        div.zapraszamy, div.adres, div.sekretariat {
            width: 100%;
            margin: 0 auto;
            @media screen and (min-width: 1200px) {
                width: 50%;
            }
        }
        div.zapraszamy p {
            font-family: Raleway;
            font-weight: 600;
            font-size: 36px;
            text-transform: uppercase;
        }

        div.adres { 
            display: flex;

            div.text {
                p {
                    margin: 20px 0;
                    font-family: Lato;
                    font-weight: 400;
                    color: #ffffff;
                }
                p.agcel {
                    margin-top: 0;
                }
                p.nip {
                    margin-bottom: 0;
                }
            }
        }
        div.sekretariat {
            display: flex;
            font-family: Lato;
            font-weight: 400;
            color: #ffffff;
            table tbody td {
                // cellpadding: 0;
                // cellspacing: collapse;
            }
            
        }
    }
}