section.realization {
    background-color: #1e1e1e;
    text-align: center;
    p {
        font-family: "Lato";
        font-weight: 400;
        color: white;
        font-size: 0.8125rem;
        padding: 22px 0;
        margin-bottom: 0;
    }
    @media screen and (min-width: 576px) {
        .agcel {
            text-align: start;
        }
        .brodnet {
            text-align: end;
        }
    }
}