section.partners {
    display: flex;
    background-color: #101010;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 200px;
        padding-top: 0;
        padding-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }
    div.p {
        width: 10%;
        p {
            display: block;
            font-family: Raleway;
            font-weight: 600;
            font-size: 1.5rem;
            text-transform: capitalize;
            color: white;
        }
    }

    div.logosGallery {
        @media screen and (min-width: 1200px) {
            width: 85%;
        }

        .splide {
            padding: 0 50px;
        }
        .splide__slide {
            vertical-align: middle;
            img {
                width : 100%;
                height: auto;
                // margin: 0 20px;
            }
        }
        .kk_arrow {
            background-color: transparent;
            svg path {
                fill: #606060;
            }
        }
    }

}