@mixin containerBigRules {
    max-width: 100vw;

    @media screen and (max-width: 1199.98px) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @media screen and (max-width: 768.98px) {
        padding-left: 30px;
        padding-right: 30px;
    }
}
